@charset "utf-8";
@import "mixin", "base";

/* ==============================
		  single.scss
================================= */

.article {
  &__header {
    border-bottom: 1px solid $gray;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2.4rem;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;

    &__date {
      color: $main_color;
    }

    &__cat {
      border:1px solid $main_color;
      background: $white;
      color: $main_color;
      margin-left: .8rem;
      padding: .8rem 1.6rem;
    }

    &__title {
      display: block;
      width: 100%;
      font-size: 1.2em;
      font-weight: 500;
      margin-top: .8em;
      line-height: 1.3;
    }

  }

  &__body {
    padding: 2.4rem 0;
  }

  &__footer {
    display: flex;
    justify-content: center;

    a {
      color: $main_color;
    }

    .center {
      margin: 0 1.6rem;
      border-bottom:1px solid $white;
      @include transition(all,0.5s);
      
      &:hover{
        border-bottom:1px solid $main_color;
      }
    }
  }


}


@media screen and (min-width: 560px) {}

@media screen and (min-width: 960px) {}

@media screen and (min-width: 1300px) {}

// @media screen and (min-width: 1888px) { }
