@import "mixin";

/*
2020年4月1日
メインカラー #e50012
ホワイト #fafafa
グレー　#e6e6e6
ブラック #333

*/

$img_path : "../asset/img/";
$main_color: #e50012;
$white: #fafafa;
$gray: #e6e6e6;
$black: #333;

$border_red_color : #d14c2b;
$col_pastelpink : #fdf6f7;
$col_vermilion: #e1352b;
$max_width : 1920px;
$con_width01 : 1160px;
$con_width02 : 980px;
$sp_lr_margin_mid: 32px;
$sp_lr_margin_small: 16px;
$sp_fontsize : 13px;

$jp_font:'Noto Sans JP','Avenir','Helvetica Neue','Helvetica','Arial','Hiragino Sans','ヒラギノ角ゴシック',YuGothic,'Yu Gothic','メイリオ', Meiryo,'ＭＳ Ｐゴシック','MS PGothic' ;

$en_font:'Work Sans', sans-serif;

$breakpoints: (
  'xs2': 'screen and (max-width: 640px) and (orientation: portrait)',
  'xs': 'screen and (max-width: 640px)',
  's' : 'screen and (max-width: 680px) and (orientation: portrait) ,screen and (max-width: 737px) and (orientation: landscape)',
  's2' : 'screen and (min-width: 681px) and (orientation: portrait) ,screen and (min-width: 738px) and (orientation: landscape)',
  'm': 'screen and (max-width: 1024px)',
  'm2': 'screen and (min-width: 1100px)',
  'l' : 'screen and (max-width: 1280px)',
  'xl': 'screen and (max-width: 1500px)',
  'xl2': 'screen and (min-width: 1500px)',
  'xxl2': 'screen and (min-width: 1920px)',
);
// @include mq(s){}