@charset "UTF-8";
/*
2020年4月1日
メインカラー #e50012
ホワイト #fafafa
グレー　#e6e6e6
ブラック #333

*/
/* ==============================
		  single.scss
================================= */
.article__header {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2.4rem;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
}

.article__header__date {
  color: #e50012;
}

.article__header__cat {
  border: 1px solid #e50012;
  background: #fafafa;
  color: #e50012;
  margin-left: .8rem;
  padding: .8rem 1.6rem;
}

.article__header__title {
  display: block;
  width: 100%;
  font-size: 1.2em;
  font-weight: 500;
  margin-top: .8em;
  line-height: 1.3;
}

.article__body {
  padding: 2.4rem 0;
}

.article__footer {
  display: flex;
  justify-content: center;
}

.article__footer a {
  color: #e50012;
}

.article__footer .center {
  margin: 0 1.6rem;
  border-bottom: 1px solid #fafafa;
  transition: all 0.5s 0s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.article__footer .center:hover {
  border-bottom: 1px solid #e50012;
}
